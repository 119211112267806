import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../Common/Breadcrumbs";
import Header from "../../../Components/Header/Header";
import Footer from "../../../Components/Footer/Footer";
import SidebarFilter from "./SidebarFilter";
import TopBar from "./TopBar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useApi } from "../../../Context/ApiProvider";
import { Image_URL } from "../../../Common/BaseURL";

const TourList = ({ toggleTheme }) => {
  const [tours, setTours] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingPage, setLoadingPage] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [filters, setFilters] = useState({
    search: "",
    tourtype: "",
    city: "",
    PriceStart: "",
    PriceEnd: "",
  });
  const api = useApi();
  const location = useLocation();
  const navigate = useNavigate();

  const resultsPerPage = 12;

  useEffect(() => {
    const fetchTours = async () => {
      setLoadingPage(true);
      try {
        const url = "Data/GetPackages";
        const params = {
          recordStart: (page - 1) * resultsPerPage,
        };

        if (filters.search) params.search = filters.search;
        if (filters.tourtype) params.tourtype = filters.tourtype;
        if (filters.city) params.city = filters.city;
        if (filters.PriceStart) params.PriceStart = filters.PriceStart;
        if (filters.PriceEnd) params.PriceEnd = filters.PriceEnd;

        const response = await api.get(url, params);
        const { data, recordsFiltered } = response.body;

        setTours(data);
        setTotalRecords(recordsFiltered);
        setTotalPages(Math.ceil(recordsFiltered / resultsPerPage));
        setLoadingPage(false);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching tour data", err);
        setLoadingPage(false);
        setLoading(false);
      }
    };

    const handleFetchData = () => {
      fetchTours();
    };

    const timeoutId = setTimeout(handleFetchData, 100);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [page, filters, api]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const queryPage = Number(queryParams.get("page")) || 1;
    const queryFilters = {
      search: queryParams.get("search") || "",
      tourtype: queryParams.get("tourtype") || "",
      city: queryParams.get("city") || "",
      PriceStart: queryParams.get("PriceStart") || "",
      PriceEnd: queryParams.get("PriceEnd") || "",
    };

    setFilters(queryFilters);
    setPage(queryPage);
  }, [location.search]);

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setPage(newPage);
      window.scrollTo(0, 0);

      const query = new URLSearchParams({
        search: filters.search,
        page: newPage,
      }).toString();

      navigate(`/TourList?${query}`);
    }
  };

  const updateFilters = (newFilters) => {
    const updatedFilters = { ...filters, ...newFilters };
    setFilters(updatedFilters);
    setPage(1);
    const query = new URLSearchParams({
      ...updatedFilters,
      page: 1,
    }).toString();
    navigate(`/TourList?${query}`);
  };

  const handleApplyFilters = (newFilters) => {
    updateFilters(newFilters);
    setPage(1); // Reset page to 1 when filters are applied
  };

  const handleFilterChange = (type, value) => {
    updateFilters({ [type]: value });
  };

  const handleClearFilter = (type) => {
    updateFilters({ [type]: "" });
  };

  const handleClearAllFilters = () => {
    updateFilters({
      search: "",
      tourtype: "",
      city: "",
      PriceStart: "",
      PriceEnd: "",
    });
  };

  const startResult = (page - 1) * resultsPerPage + 1;
  const endResult = Math.min(page * resultsPerPage, totalRecords);

  if (loading && page === 1) {
    return (
      <div className="loading">
        <h2 className="highlights fancy-font font-400">Routes and Tours</h2>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    );
  }

  return (
    <div>
      <Header toggleTheme={toggleTheme} />
      <Breadcrumbs heading="Tour List" link="/TourList" />

      <section className="tour-list-section section-padding2">
        <div className="container">
          <div>
            <div className="d-flex align-items-center justify-content-between mb-20">
              <div>
                <h4 className="title">Applied Filters</h4>
              </div>
              <div>
                <button
                  className="btn-primary-icon-sm-filter"
                  onClick={handleClearAllFilters}
                >
                  Clear All
                </button>
              </div>
            </div>
            <div className="row pb-20 g-2">
              {Object.keys(filters).map((key) =>
                filters[key] ? (
                  <div className="col-lg-2 col-md-3 col-sm-4" key={key}>
                    <div className="applied-filters d-flex align-items-center justify-content-between">
                      <span className="filter-text">{filters[key]}</span>
                      <button
                        className="clear-filters ms-2"
                        aria-label={`Clear ${key} filter`}
                        onClick={() => handleClearFilter(key)}
                      >
                        <i className="ri-close-line"></i>
                      </button>
                    </div>
                  </div>
                ) : null
              )}
            </div>
          </div>
          <div className="row g-4">
            <div className="col-xl-3">
              <SidebarFilter
                onTourTypeChange={(tourType) =>
                  handleFilterChange("tourtype", tourType)
                }
                onCityChange={(city) => handleFilterChange("city", city)}
                onApplyFilters={handleApplyFilters}
              />
            </div>
            <div className="col-xl-9">
              <TopBar
                currentResults={tours.length}
                totalResults={totalRecords}
                startResult={startResult}
                endResult={endResult}
              />
              <div className="all-tour-list">
                {loadingPage && (
                  <div className="loading">
                    <h2 className="highlights fancy-font font-400">
                      Routes and Tours
                    </h2>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                )}
                <div className="row g-4">
                  {tours.length === 0 ? (
                    <div>No Record found!</div>
                  ) : (
                    !loadingPage &&
                    tours &&
                    tours.map((tour) => (
                      <div
                        key={tour.packageId}
                        className="col-xl-4 col-lg-4 col-sm-6"
                      >
                        <div className="package-card">
                          <div className="package-img imgEffect4">
                            <Link to={`/TourDetail/${tour.packageId}`}>
                              <img
                                src={
                                  tour.imagename
                                    ? `${Image_URL}/Package/${tour.imagename}`
                                    : "assets/images/package/package-4.png"
                                }
                                alt={tour.packageName}
                              />
                            </Link>
                          </div>
                          <div className="package-content">
                            <h4 className="area-name">
                              <Link to={`/TourDetail/${tour.packageId}`}>
                                {tour.packageName}
                              </Link>
                            </h4>
                            <div className="location">
                              <i className="ri-map-pin-line"></i>
                              <div className="name">
                                {tour.cityname}, {tour.stateName}
                              </div>
                            </div>
                            <div className="packages-person">
                              <div className="count">
                                <i className="ri-time-line"></i>
                                <p className="pera">
                                  {tour.days} Days {tour.nights} Nights
                                </p>
                              </div>
                            </div>
                            <div className="price-review">
                              <div className="d-flex gap-10">
                                <p className="light-pera">From</p>
                                <p className="pera">₹{tour.priceForDouble}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>

                {/* Bootstrap Pagination */}
                {totalRecords >= resultsPerPage && (
                  <nav className="Pager5" aria-label="...">
                    <ul className="pagination pagination-circle justify-content-center">
                      <li
                        className={`page-item ${page === 1 ? "disabled" : ""}`}
                      >
                        <Link
                          className="page-link prev"
                          aria-label="Previous"
                          onClick={() => handlePageChange(page - 1)}
                        >
                          <span aria-hidden="true">&laquo;</span>
                        </Link>
                      </li>
                      {[...Array(totalPages).keys()].map((num) => (
                        <li
                          key={num + 1}
                          className={`page-item ${
                            page === num + 1 ? "active" : ""
                          }`}
                        >
                          <Link
                            className="page-link"
                            to="#"
                            onClick={() => handlePageChange(num + 1)}
                          >
                            {num + 1}
                          </Link>
                        </li>
                      ))}

                      <li
                        className={`page-item ${
                          page === totalPages ? "disabled" : ""
                        }`}
                      >
                        <Link
                          className="page-link next"
                          aria-label="Next"
                          onClick={() => handlePageChange(page + 1)}
                        >
                          <span aria-hidden="true">&raquo;</span>
                        </Link>
                      </li>
                    </ul>
                  </nav>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default TourList;
