import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import { Autoplay } from "swiper/modules";
import brand1 from "../../Assets/images/brand/brand-1.png";
import brand2 from "../../Assets/images/brand/brand-2.png";
import brand3 from "../../Assets/images/brand/brand-3.png";
import brand4 from "../../Assets/images/brand/brand-4.png";
import brand5 from "../../Assets/images/brand/brand-5.png";
import brand6 from "../../Assets/images/brand/brand-6.png";

const Brand = () => {
  return (
    <div className="brand-area">
      <div className="container">
        <div className="swiper brandSwiper-active">
          <Swiper
            centeredSlides={true}
            loop={true}
            speed={500}
            slidesPerView={2}
            spaceBetween={40}
            autoplay={{
              delay: 3000,
            }}
            breakpoints={{
              992: {
                slidesPerView: 5,
              },
              768: {
                slidesPerView: 3,
              },
            }}
            modules={[Autoplay]}
          >
            <SwiperSlide>
              <img src={brand1} alt="travello" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={brand2} alt="travello" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={brand3} alt="travello" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={brand4} alt="travello" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={brand5} alt="travello" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={brand6} alt="travello" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={brand3} alt="travello" />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Brand;
