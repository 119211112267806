import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import useFilterCommon from "../../../Hooks/useFilterCommon";
import $ from "jquery";
import "select2/dist/js/select2.full.min.js";
import "select2/dist/css/select2.min.css";
import "daterangepicker/daterangepicker.css";
import "daterangepicker";

const TravelCard = ({ content }) => {
  const {
    totalGuests,
    handleAdultsChange,
    handleChildrenChange,
    handleInfantsChange,
    handleGuestClick,
    adults,
    children,
    isGuestDropdownOpen,
    infants,
    setIsGuestDropdownOpen,
    showTotalGuests,
    setShowTotalGuests,
  } = useFilterCommon();

  const handleDoneClick = () => {
    setShowTotalGuests(true); // Show total guests when Done is clicked
    setIsGuestDropdownOpen(false); // Optionally close the dropdown
  };

  const handleDateDropdownClick = () => {
    $(".date-time-result").data("daterangepicker").show();
  };

  useEffect(() => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate()); // Tomorrow's date

    // Initialize DateRangePicker
    const datepicker = $(".date-time-result")
      .daterangepicker(
        {
          opens: "left",
          startDate: today,
          minDate: tomorrow,
        },
        function (start, end, label) {
          console.log(
            "A new date selection was made: " +
              start.format("YYYY-MM-DD") +
              " to " +
              end.format("YYYY-MM-DD")
          );
        }
      )
      .data("daterangepicker");

    // Clean up function
    return () => {
      if (datepicker) {
        datepicker.remove();
      }
    };
  }, []);

  return (
    <div className="date-travel-card position-sticky top-0">
      <div className="price-review">
        <div className="d-flex gap-10 align-items-end">
          <p className="light-pera">From</p>
          <p className="pera">₹{content?.priceForDouble}</p>
        </div>
        <div className="rating">
          <p className="pera">Price varies by group size</p>
        </div>
      </div>
      <h4 className="heading-card">Select Date and Travelers</h4>
      <div className="date-time-dropdown">
        <i className="ri-time-line"></i>
        <p className="date-time-result" onClick={handleDateDropdownClick}>
          Wednesday, Jan 17, 2023
        </p>
      </div>
      <div className="dropdown-section position-relative user-picker-dropdown">
        <div className="d-flex gap-12 align-items-center">
          <i className="dropdown-icon ri-user-line"></i>
          <div className="custom-dropdown" onClick={handleGuestClick}>
            <h4 className="title">Guests</h4>
            <div className="arrow">
              <i className="ri-arrow-down-s-line"></i>
            </div>
          </div>
        </div>
        {showTotalGuests && <div className="user-result">{totalGuests}</div>}
        <div
          className="user-picker dropdown-shadow"
          style={{
            display: isGuestDropdownOpen ? "block" : "none",
          }}
        >
          <div className="user-category">
            <div className="category-name">
              <h4 className="title">Adults</h4>
              <p className="pera">12years and above</p>
            </div>
            <div className="qty-container">
              <button
                className="qty-btn-minus mr-1"
                type="button"
                onClick={() => handleAdultsChange(adults - 1)}
              >
                <i className="ri-subtract-fill"></i>
              </button>
              <input
                type="text"
                name="qty"
                value={adults}
                className="input-qty input-rounded"
                onChange={(e) =>
                  handleAdultsChange(parseInt(e.target.value, 10))
                }
              />
              <button
                className="qty-btn-plus ml-1"
                type="button"
                onClick={() => handleAdultsChange(adults + 1)}
              >
                <i className="ri-add-fill"></i>
              </button>
            </div>
          </div>
          <div className="user-category">
            <div className="category-name">
              <h4 className="title">Children</h4>
              <p className="pera">2-11 years</p>
            </div>
            <div className="qty-container">
              <button
                className="qty-btn-minus mr-1"
                type="button"
                onClick={() => handleChildrenChange(children - 1)}
              >
                <i className="ri-subtract-fill"></i>
              </button>
              <input
                type="text"
                name="qty"
                value={children}
                className="input-qty input-rounded"
                onChange={(e) =>
                  handleChildrenChange(parseInt(e.target.value, 10))
                }
              />
              <button
                className="qty-btn-plus ml-1"
                type="button"
                onClick={() => handleChildrenChange(children + 1)}
              >
                <i className="ri-add-fill"></i>
              </button>
            </div>
          </div>
          <div className="user-category">
            <div className="category-name">
              <h4 className="title">infant</h4>
              <p className="pera">belwo 2 years</p>
            </div>
            <div className="qty-container">
              <button
                className="qty-btn-minus mr-1"
                type="button"
                onClick={() => handleInfantsChange(infants - 1)}
              >
                <i className="ri-subtract-fill"></i>
              </button>
              <input
                type="text"
                name="qty"
                value={infants}
                className="input-qty input-rounded"
                onChange={(e) =>
                  handleInfantsChange(parseInt(e.target.value, 10))
                }
              />
              <button
                className="qty-btn-plus ml-1"
                type="button"
                onClick={() => handleInfantsChange(infants + 1)}
              >
                <i className="ri-add-fill"></i>
              </button>
            </div>
          </div>
          <div className="btn-section" onClick={handleDoneClick}>
            <Link to="" className="done-btn">
              Done
            </Link>
          </div>
        </div>
      </div>
      <div className="mt-30">
        <button type="submit" className="send-btn w-100">
          Check Availability
        </button>
      </div>
      <div className="footer bg-transparent">
        <h4 className="title">Free Cancellation</h4>
        <p className="pera">Up to 24 hours in advance</p>
      </div>
    </div>
  );
};

export default TravelCard;
