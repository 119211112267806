import React from "react";
import { Link } from "react-router-dom";
import shape from "../../Assets/images/icon/shape.png"
import des1 from "../../Assets/images/destination/destination-01.png"

const Destination = () => {
  return (
    <section className="destination-area destination-bg-before">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-7">
            <div className="section-title text-center mx-430 mx-auto position-relative">
              <span className="highlights">Destination List</span>
              <h4 className="title">Explore The Beautiful Places Around World</h4>
            </div>
          </div>
        </div>
        <div className="row g-4">
          <div className="col-xl-4 col-lg-6 col-md-6">
             <Link to="/DestinationDetail" className="destination-banner">
              <img
                src={des1}
                alt="travello"
              />
              <div className="destination-content">
                <div className="ratting-badge">
                  <i className="ri-star-s-fill"></i>
                  <span>4.5</span>
                </div>
                <div className="destination-info">
                  <div className="destination-name">
                    <p className="pera">Spain</p>
                    <div className="location">
                      <i className="ri-map-pin-line"></i>
                      <p className="name">Malaga View</p>
                    </div>
                  </div>
                  <div className="button-section">
                    <div className="arrow-btn">
                      <i className="ri-arrow-right-line"></i>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-xl-4 col-lg-6 col-md-6">
             <Link to="/DestinationDetail" className="destination-banner">
              <img
                src={des1}
                alt="travello"
              />
              <div className="destination-content">
                <div className="ratting-badge">
                  <i className="ri-star-s-fill"></i>
                  <span>4.5</span>
                </div>
                <div className="destination-info">
                  <div className="destination-name">
                    <p className="pera">Spain</p>
                    <div className="location">
                      <i className="ri-map-pin-line"></i>
                      <p className="name">Malaga View</p>
                    </div>
                  </div>
                  <div className="button-section">
                    <div className="arrow-btn">
                      <i className="ri-arrow-right-line"></i>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-xl-4 col-lg-12 col-md-12">
             <Link to="/DestinationDetail" className="destination-banner">
              <img
                src={des1}
                alt="travello"
              />
              <div className="destination-content">
                <div className="ratting-badge">
                  <i className="ri-star-s-fill"></i>
                  <span>4.5</span>
                </div>
                <div className="destination-info">
                  <div className="destination-name">
                    <p className="pera">Spain</p>
                    <div className="location">
                      <i className="ri-map-pin-line"></i>
                      <p className="name">Malaga View</p>
                    </div>
                  </div>
                  <div className="button-section">
                    <div className="arrow-btn">
                      <i className="ri-arrow-right-line"></i>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="destination-gallery">
            <div className="row g-4">
              <div className="col-lg-3 col-md-6 col-sm-6">
                 <Link to="/DestinationDetail" className="destination-banner">
                  <img
                    src={des1}
                    alt="travello"
                  />
                  <div className="destination-content">
                    <div className="ratting-badge">
                      <i className="ri-star-s-fill"></i>
                      <span>4.5</span>
                    </div>
                    <div className="destination-info">
                      <div className="destination-name">
                        <p className="pera">Switzerland</p>
                        <div className="location">
                          <i className="ri-map-pin-line"></i>
                          <p className="name">Zürich View</p>
                        </div>
                      </div>
                      <div className="button-section">
                        <div className="arrow-btn">
                          <i className="ri-arrow-right-line"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6">
                 <Link to="/DestinationDetail" className="destination-banner">
                  <img
                    src={des1}
                    alt="travello"
                  />
                  <div className="destination-content">
                    <div className="ratting-badge">
                      <i className="ri-star-s-fill"></i>
                      <span>4.5</span>
                    </div>
                    <div className="destination-info">
                      <div className="destination-name">
                        <p className="pera">Australia</p>
                        <div className="location">
                          <i className="ri-map-pin-line"></i>
                          <p className="name">Melbourne View</p>
                        </div>
                      </div>
                      <div className="button-section">
                        <div className="arrow-btn">
                          <i className="ri-arrow-right-line"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6">
                 <Link to="/DestinationDetail" className="destination-banner">
                  <img
                    src={des1}
                    alt="travello"
                  />
                  <div className="destination-content">
                    <div className="ratting-badge">
                      <i className="ri-star-s-fill"></i>
                      <span>4.5</span>
                    </div>
                    <div className="destination-info">
                      <div className="destination-name">
                        <p className="pera">Canada</p>
                        <div className="location">
                          <i className="ri-map-pin-line"></i>
                          <p className="name">Toronto View</p>
                        </div>
                      </div>
                      <div className="button-section">
                        <div className="arrow-btn">
                          <i className="ri-arrow-right-line"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6">
                 <Link to="/DestinationDetail" className="destination-banner">
                  <img
                    src={des1}
                    alt="travello"
                  />
                  <div className="destination-content">
                    <div className="ratting-badge">
                      <i className="ri-star-s-fill"></i>
                      <span>4.5</span>
                    </div>
                    <div className="destination-info">
                      <div className="destination-name">
                        <p className="pera">Canada</p>
                        <div className="location">
                          <i className="ri-map-pin-line"></i>
                          <p className="name">Toronto View</p>
                        </div>
                      </div>
                      <div className="button-section">
                        <div className="arrow-btn">
                          <i className="ri-arrow-right-line"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="shape">
        <img src={shape} alt="travello" />
      </div>
    </section>
  );
};

export default Destination;
