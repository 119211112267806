import React, { useEffect, useRef } from "react";
import $ from "jquery";
import "select2/dist/js/select2.full.min.js";
import "select2/dist/css/select2.min.css";

const TopBar = ({ currentResults, totalResults, startResult, endResult }) => {
  const sortingRef = useRef(null);

  useEffect(() => {
    // Store references in local variables
    const destinationSelect = $(sortingRef.current);

    // Initialize Select2 for destination and tour type dropdowns
    destinationSelect.select2({
      containerCssClass: "custom-select2-dropdown",
      dropdownCssClass: "custom-select2-dropdown-container",
    });

    // Clean up function
    return () => {
      // Destroy Select2 instances using local variables
      if (destinationSelect && destinationSelect.length) {
        destinationSelect.select2("destroy");
      }
    };
  }, []);

  return (
    <div className="showing-result">
      <h4 className="title">
        Showing {startResult}-{endResult} of {totalResults} Results
      </h4>
      <div className="d-flex gap-10 align-items-center">
        <div className="expand-icon hamburger block d-xl-none" id="hamburger">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M3 7H10M10 7C10 8.65685 11.3431 10 13 10H14C15.6569 10 17 8.65685 17 7C17 5.34315 15.6569 4 14 4H13C11.3431 4 10 5.34315 10 7ZM16 17H21M20 7H21M3 17H6M6 17C6 18.6569 7.34315 20 9 20H10C11.6569 20 13 18.6569 13 17C13 15.3431 11.6569 14 10 14H9C7.34315 14 6 15.3431 6 17Z"
              stroke="#071516"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div className="sorting-dropdown">
          <select ref={sortingRef} className="select2">
            <option value="popular">Sort by Popular</option>
            <option value="low">Price low to high</option>
            <option value="high">Price high to low</option>
            <option value="new">Sort by Newset</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
