import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
// import required modules
import { Autoplay, Navigation } from "swiper/modules";
import { useApi } from "../../Context/ApiProvider";
import { Image_URL } from "../../Common/BaseURL";

const FeatureTour = () => {
  const [feature, setFeature] = useState([]);
  const api = useApi();

  useEffect(() => {
    const fetchFeatureData = async () => {
      try {
        const response = await api.get(`Data/GetPackageExplore?etype=feature`); // Update API URL
        setFeature(response.body.data);
      } catch (error) {
        console.error("Error fetching feature:", error);
      }
    }

    const handleFetchData = () => {
      fetchFeatureData();
    };

    const timeoutId = setTimeout(handleFetchData, 100);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [api])

  return (
    <section className="feature-area feature-area-bg section-padding2">
      <div className="container">
        <div className="row justify-content-center position-relative z-10">
          <div className="col-xl-7 col-lg-7">
            <div className="section-title mx-430 mx-auto text-center">
              <span className="highlights fancy-font font-400">
                Features Tour
              </span>
              <h4 className="title">Most Favorite Tour Place in The World</h4>
            </div>
          </div>
        </div>
        <div className="row g-4 position-relative z-10">
          <div className="swiper favSwiper-active">
            <Swiper
              loop={true}
              slidesPerView={1}
              spaceBetween={24}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              navigation={{
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              }}
              modules={[Navigation, Autoplay]}
              breakpoints={{
                1200: {
                  slidesPerView: 4,
                },
                992: {
                  slidesPerView: 3,
                },
                576: {
                  slidesPerView: 2,
                },
              }}
            >
              {feature && feature.map((feature, index) => (
                <SwiperSlide key={index}>
                  <div className="package-card">
                    <div className="package-img imgEffect4">
                      <Link to={`/TourDetail/${feature.packageId}`}>
                        <img
                          src={
                            feature.imagename
                              ? `${Image_URL}/Package/${feature.imagename}`
                              : "assets/images/package/package-4.png"
                          }
                          alt={feature.packageName}
                        />
                      </Link>
                      {/* <div className="image-badge">
                        <p className="pera">Featured</p>
                      </div>
                      <div className="fav-badge">
                        <i className="ri-heart-fill"></i>
                      </div> */}
                    </div>
                    <div className="package-content">
                      <div className="location">
                        <i className="ri-map-pin-line"></i>
                        <div className="name">{feature.cityname}, {feature.stateName}</div>
                      </div>
                      <h4 className="area-name">
                        <Link to={`/TourDetail/${feature.packageId}`}>
                          {feature.packageName}
                        </Link>
                      </h4>
                      <div className="packages-person mb-16">
                        <div className="count">
                          <i className="ri-time-line"></i>
                          <p className="pera">{feature.days} Days {feature.nights} Nights</p>
                        </div>
                        {/* <div className="count">
                          <i className="ri-user-line"></i>
                          <p className="pera">2 Person</p>
                        </div> */}
                      </div>
                      <div className="price-review mb-0">
                        <div className="d-flex gap-10">
                          <p className="light-pera">From</p>
                          <p className="pera">₹{feature.priceForDouble}</p>
                        </div>
                        {/* <div className="rating">
                          <i className="ri-star-s-fill"></i>
                          <p className="pera">4.7 (20 Reviews)</p>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="swiper-button-next">
              <i className="ri-arrow-right-s-line"></i>
            </div>
            <div className="swiper-button-prev">
              <i className="ri-arrow-left-s-line"></i>
            </div>
          </div>
        </div>
        <div className="row position-relative z-10">
          <div className="col-12 text-center">
            <div className="section-button d-inline-block">
              <Link to="/TourList">
                <div className="btn-primary-icon-sm border-radius-20">
                  <p className="pera">View All Tour</p>
                  <i className="ri-arrow-right-up-line"></i>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeatureTour;
