import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useFilterCommon from "../../Hooks/useFilterCommon";
import { useApi } from "../../Context/ApiProvider";

const PlanArea = ({ tours }) => {
  const [destinations, setDestinations] = useState([]);
  const api = useApi();

  const {
    totalGuests,
    handleAdultsChange,
    handleChildrenChange,
    handleInfantsChange,
    handleGuestClick,
    handleDateDropdownClick,
    destinationRef,
    tourTypeRef,
    adults,
    children,
    isGuestDropdownOpen,
    infants,
    setIsGuestDropdownOpen,
  } = useFilterCommon();

  useEffect(() => {
    const fetchDestinations = async () => {
      try {
        const response = await api.get("Data/GetDestination");
        setDestinations(response.body.data || []);
      } catch (err) {
        console.error("Error fetching destinations", err);
      }
    };

    const handleFetchData = () => {
      fetchDestinations();
    };

    const timeoutId = setTimeout(handleFetchData, 100);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [api]); // include api if it is subject to change

  return (
    <section className="plan-area">
      <div className="container">
        <div className="plan-section plan-shadow">
          <div className="select-dropdown-section">
            <div className="d-flex gap-10 align-items-center">
              <i className="ri-map-pin-line"></i>
              <h4 className="select2-title">Destination</h4>
            </div>
            <select ref={destinationRef} className="destination-dropdown">
              {destinations &&
                destinations.map((destination) => (
                  <option key={destination.cityid} value={destination.cityid}>
                    {destination.cityName}, {destination.stateName}
                  </option>
                ))}
            </select>
          </div>
          <div className="divider plan-divider d-none d-lg-block"></div>
          <div className="select-dropdown-section">
            <div className="d-flex gap-10 align-items-center">
              <i className="ri-flight-takeoff-fill"></i>
              <h4 className="select2-title">Tour Type</h4>
            </div>
            <select ref={tourTypeRef} className="destination-dropdown">
              {tours &&
                tours.map((tour) => (
                  <option key={tour.tourTypeId} value={tour.tourTypeId}>
                    {tour.tourTypeName}
                  </option>
                ))}
            </select>
          </div>
          <div className="divider plan-divider d-none d-lg-block"></div>
          <div className="dropdown-section">
            <div className="d-flex gap-10 align-items-center">
              <i className="ri-time-line"></i>
              <div
                className="custom-dropdown custom-date"
                onClick={handleDateDropdownClick}
              >
                <h4 className="title">Date From</h4>
                <div className="arrow">
                  <i className="ri-arrow-down-s-line"></i>
                </div>
              </div>
            </div>
            <input
              aria-label="date"
              readOnly
              className="date-result"
              type="text"
              name="daterange"
              value="01/12/2023 - 01/12/2023"
            />
          </div>
          <div className="divider plan-divider d-none d-lg-block"></div>
          <div className="dropdown-section position-relative user-picker-dropdown">
            <div className="d-flex gap-10 align-items-center">
              <i className="ri-user-line"></i>
              <div className="custom-dropdown" onClick={handleGuestClick}>
                <h4 className="title">Guests</h4>
                <div className="arrow">
                  <i className="ri-arrow-down-s-line"></i>
                </div>
              </div>
            </div>
            <div className="user-result">{totalGuests || 0}</div>
            <div
              className="user-picker dropdown-shadow"
              style={{ display: isGuestDropdownOpen ? "block" : "none" }}
            >
              <div className="user-category">
                <div className="category-name">
                  <h4 className="title">Adults</h4>
                  <p className="pera">12years and above</p>
                </div>
                <div className="qty-container">
                  <button
                    className="qty-btn-minus mr-1"
                    type="button"
                    onClick={() => handleAdultsChange(adults - 1)}
                  >
                    <i className="ri-subtract-fill"></i>
                  </button>
                  <input
                    type="text"
                    name="qty"
                    value={adults}
                    className="input-qty input-rounded"
                    onChange={(e) =>
                      handleAdultsChange(parseInt(e.target.value, 10))
                    }
                  />
                  <button
                    className="qty-btn-plus ml-1"
                    type="button"
                    onClick={() => handleAdultsChange(adults + 1)}
                  >
                    <i className="ri-add-fill"></i>
                  </button>
                </div>
              </div>
              <div className="user-category">
                <div className="category-name">
                  <h4 className="title">Children</h4>
                  <p className="pera">2-11 years</p>
                </div>
                <div className="qty-container">
                  <button
                    className="qty-btn-minus mr-1"
                    type="button"
                    onClick={() => handleChildrenChange(children - 1)}
                  >
                    <i className="ri-subtract-fill"></i>
                  </button>
                  <input
                    type="text"
                    name="qty"
                    value={children}
                    className="input-qty input-rounded"
                    onChange={(e) =>
                      handleChildrenChange(parseInt(e.target.value, 10))
                    }
                  />
                  <button
                    className="qty-btn-plus ml-1"
                    type="button"
                    onClick={() => handleChildrenChange(children + 1)}
                  >
                    <i className="ri-add-fill"></i>
                  </button>
                </div>
              </div>
              <div className="user-category">
                <div className="category-name">
                  <h4 className="title">infant</h4>
                  <p className="pera">below 2 years</p>
                </div>
                <div className="qty-container">
                  <button
                    className="qty-btn-minus mr-1"
                    type="button"
                    onClick={() => handleInfantsChange(infants - 1)}
                  >
                    <i className="ri-subtract-fill"></i>
                  </button>
                  <input
                    type="text"
                    name="qty"
                    value={infants}
                    className="input-qty input-rounded"
                    onChange={(e) =>
                      handleInfantsChange(parseInt(e.target.value, 10))
                    }
                  />
                  <button
                    className="qty-btn-plus ml-1"
                    type="button"
                    onClick={() => handleInfantsChange(infants + 1)}
                  >
                    <i className="ri-add-fill"></i>
                  </button>
                </div>
              </div>
              <div
                className="btn-section"
                onClick={() => setIsGuestDropdownOpen(false)}
              >
                <Link to="/" className="done-btn">
                  Done
                </Link>
              </div>
            </div>
          </div>
          <div className="sign-btn">
            <Link to="/TourList" className="btn-secondary-lg">
              Search Plan
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PlanArea;
