import React, { useEffect, useState } from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import Breadcrumbs from "../../Common/Breadcrumbs";
import { useApi } from "../../Context/ApiProvider";
import { useForm } from "react-hook-form";
import "react-toastify/dist/ReactToastify.css";

const Contact = ({ toggleTheme }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const api = useApi();

  setTimeout(() => {
    setLoading(false);
  }, 2000);

  const onSubmit = async (data) => {
    setIsLoading(true);
    setError(""); // Clear previous errors
    setSuccessMessage("");

    try {
      await api.post("ContactUs/submit", data);
      setSuccessMessage("Message sent successful!");

      setTimeout(() => {
        setSuccessMessage("");
      }, 3000);
      reset();
    } catch (error) {
      // Handle error based on response structure
      setError(error || "Failed to send message."); // Set specific error message or fallback
      setTimeout(() => {
        setError("");
      }, 3000);
    } finally {
      setIsLoading(false); // Always stop loading spinner
    }
  };

  if (loading) {
    return <div className="loading">
      <h2 className="highlights fancy-font font-400">Routes and Tours</h2>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  }

  return (
    <div>
      <Header toggleTheme={toggleTheme} />
      <Breadcrumbs heading="Contact" link="/Contact" />

      <section className="contact-area section-padding2">
        <div className="position-relative contact-bg-before">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-7 col-lg-9">
                <div className="contact-card">
                  <h4 className="contact-heading">
                    Feel Free to Write us Anytime
                  </h4>
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="contact-form"
                  >
                    <div className="row g-4">
                      <div className="col-sm-6">
                        <input
                          className="custom-form"
                          type="text"
                          placeholder="Enter your name"
                          {...register("name", {
                            required: "Name is required",
                          })}
                        />
                        {errors.name && (
                          <span className="text-red">
                            {errors.name.message}
                          </span>
                        )}
                      </div>
                      <div className="col-sm-6">
                        <input
                          className="custom-form"
                          type="email"
                          placeholder="Enter your email"
                          {...register("emailId", {
                            required: "Email is required",
                            pattern: {
                              value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                              message: "Invalid email address",
                            },
                          })}
                        />
                        {errors.emailId && (
                          <span className="text-red">
                            {errors.emailId.message}
                          </span>
                        )}
                      </div>
                      <div className="col-sm-6">
                        <input
                          className="custom-form"
                          type="text"
                          placeholder="Your Phone"
                          {...register("phone", {
                            required: "Phone number is required",
                            pattern: {
                              value: /^[0-9]{10}$/,
                              message: "Phone number must be 10 digits",
                            },
                          })}
                        />
                        {errors.phone && (
                          <span className="text-red">
                            {errors.phone.message}
                          </span>
                        )}
                      </div>
                      <div className="col-sm-6">
                        <input
                          className="custom-form"
                          type="text"
                          placeholder="Select subject"
                          {...register("subject", {
                            required: "Subject is required",
                          })}
                        />
                        {errors.subject && (
                          <span className="text-red">
                            {errors.subject.message}
                          </span>
                        )}
                      </div>
                      <div className="col-sm-12">
                        <textarea
                          className="custom-form-textarea"
                          rows="3"
                          placeholder="Enter your message..."
                          {...register("message", {
                            required: "Message is required",
                          })}
                        ></textarea>
                        {errors.message && (
                          <span className="text-red">
                            {errors.message.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="mt-40">
                      <button
                        type="submit"
                        className="send-btn"
                        disabled={isLoading}
                      >
                        {isLoading ? "Sending..." : "Send Message"}
                      </button>
                    </div>

                    {error && (
                      <p className="error-message text-danger">{error}</p>
                    )}
                    {successMessage && (
                      <p className="success-message text-success">
                        {successMessage}
                      </p>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Map */}
      <iframe
        className="map-frame"
        src="https://www.google.com/maps/embed/v1/place?q=USA,+Turkish&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
        height="500"
        style={{ border: "15px" }}
        allowFullScreen=""
        loading="lazy"
        title="map"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>

      <Footer />
    </div>
  );
};

export default Contact;
