import React from "react";
import { Link } from "react-router-dom";

const AboutUs = ({ content }) => {
  const imageTag = content?.contents.match(/<img[^>]*>/g);
  const parser = new DOMParser();
  const imageElement =
    imageTag && imageTag.length > 0
      ? parser.parseFromString(imageTag[0], "text/html").querySelector("img")
      : null;

  return (
    <section className="about-area">
      <div className="container">
        <div className="row g-4">
          <div className="col-xl-5 col-lg-6">
            <div className="section-title mx-430 mb-30 w-md-100">
              <span className="highlights fancy-font font-400">{content?.title}</span>
              <div
                className="pera"
                dangerouslySetInnerHTML={{
                  __html: content?.contents.replace(/<img[^>]*>/g, ""),
                }}
              />
              <div className="section-button mt-27 d-inline-block">
                <Link to="/About" className="btn-primary-icon-sm radius-20">
                  <p className="pera mt-0">Learn More</p>
                  <i className="ri-arrow-right-up-line"></i>
                </Link>
              </div>
              <div className="about-imp-link mt-40">
                <div className="icon">
                  <i className="ri-user-line"></i>
                </div>
                <div className="content">
                  <p className="pera font-16">
                    <span className="font-700">2,500</span> People Booked
                    Tomorrow Land Event in the Last 24 hours
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-7 col-lg-6">
            <div className="about-count-section about-count-before-bg">
              <div className="banner">
                {imageTag && (
                  <img src={imageElement.src} alt={imageElement.alt} />
                )}
              </div>
              <div className="all-count-list">
                <div className="details">
                  <h4 className="count">150k</h4>
                  <p className="pera">Happy Traveler</p>
                </div>
                <div className="divider"></div>
                <div className="details">
                  <h4 className="count">95.7%</h4>
                  <p className="pera">Satisfaction Rate</p>
                </div>
                <div className="divider"></div>
                <div className="details">
                  <h4 className="count">5000+</h4>
                  <p className="pera">Tour Completed</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
