import React from "react";
import { Link } from "react-router-dom";
import FooterMiddleArea from "./FooterMiddleArea";
import FooterBottomArea from "./FooterBottomArea";

const Footer = () => {
  return (
    <footer>
      <div className="footer-wrapper footer-bg">
        <div className="container">
          <div className="footer-area">
            <div className="row g-4">
              <div className="col-xl-3 col-lg-4 col-sm-6">
                <div className="single-footer-caption">
                  <div className="footer-tittle">
                    <h4 className="title">Company</h4>
                    <ul className="listing">
                      <li className="single-lsit">
                        <Link to="/About">About Us</Link>
                      </li>
                      <li className="single-lsit">
                        <Link to="/">News</Link>
                      </li>
                      <li className="single-lsit">
                        <Link to="/faq">Faq</Link>
                      </li>
                      <li className="single-lsit">
                        <Link to="/Contact">Contact</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6">
                <div className="single-footer-caption">
                  <div className="footer-tittle">
                    <h4 className="title">Explore</h4>
                    <ul className="listing">
                      <li className="single-lsit">
                        <Link to="/faq">Faq</Link>
                      </li>
                      <li className="single-lsit">
                        <Link to="/TourList">Tour Listings</Link>
                      </li>
                      <li className="single-lsit">
                        <Link to="/DestinationList">Destination</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6">
                <div className="single-footer-caption">
                  <div className="footer-tittle">
                    <h4 className="title">Quick Links</h4>
                    <ul className="listing">
                      <li className="single-lsit">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="single-lsit">
                        <Link to="/About">About Us</Link>
                      </li>
                      <li className="single-lsit">
                        <Link to="/Contact">Contact Us</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6">
                <div className="single-footer-caption">
                  <div className="footer-tittle">
                    <h4 className="title">Contact</h4>
                    <ul className="listing">
                      <li className="single-lsit">
                        <Link to="/" className="mb-20 d-block">
                          70/A Floor Divo Tower Melbourne, Australia
                        </Link>
                      </li>
                      <li className="single-lsit">
                        <Link to="/">
                          <div className="d-flex gap-12">
                            <i className="ri-phone-line"></i> (00) +888 123456
                            789
                          </div>
                        </Link>
                      </li>
                      <li className="single-lsit">
                        <Link to="/">
                          <div className="d-flex gap-12">
                            <i className="ri-mail-line"></i> example@gmail.com
                          </div>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-middle-area">
            <FooterMiddleArea />
          </div>
        </div>

        <div className="footer-bottom-area">
          <FooterBottomArea />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
