import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
// import required modules
import { Autoplay } from "swiper/modules";
import logo from "../../Assets/images/logo/logo.png";
import test from "../../Assets/images/testimonial/testimonial-1.jpeg"

const Testimonial = () => {
  return (
    <section className="testimonial-area section-padding">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-7">
            <div className="section-title mx-430 mx-auto text-center">
              <span className="highlights">Testimonial</span>
              <h4 className="title">What People Have Said About Our Service</h4>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex flex-wrap gap-24">
        <div className="swiper bulletLeftSwiper-active">
          <Swiper
            allowTouchMove={true}
            slidesPerView={1}
            speed={6000}
            loop={true}
            autoplay={{
              delay: 0,
              disableOnInteraction: true,
              reverseDirection: true,
              pauseOnMouseEnter: true,
            }}
            spaceBetween={24}
            breakpoints={{
              1400: {
                slidesPerView: 5,
              },
              992: {
                slidesPerView: 3,
              },
              576: {
                slidesPerView: 2,
              },
            }}
            modules={[Autoplay]}
          >
            <SwiperSlide className="testimonial-card">
              <div className="testimonial-header">
                <div className="user-img">
                  <img
                    src={test}
                    alt="travello"
                  />
                </div>
                <div className="user-info">
                  <p className="name">David Malan</p>
                  <p className="designation">Traveler</p>
                </div>
              </div>
              <div className="testimonial-body">
                <p className="pera">
                  Lorem ipsum dolor sit amet consectetur. Et amet nulla in
                  adipiscing. Donec tincidunt dui vel adipiscing sit turpis
                  neque at cursus. Dignissim scelerisque mattis ultricies vitae.
                </p>
              </div>
              <div className="testimonial-footer">
                <div className="logo">
                  <img src={logo} alt="travello" className="changeLogo" />
                </div>
                <p className="date">Jan 20, 2025</p>
              </div>
            </SwiperSlide>
            <SwiperSlide className="testimonial-card">
              <div className="testimonial-header">
                <div className="user-img">
                  <img
                    src={test}
                    alt="travello"
                  />
                </div>
                <div className="user-info">
                  <p className="name">David Malan</p>
                  <p className="designation">Traveler</p>
                </div>
              </div>
              <div className="testimonial-body">
                <p className="pera">
                  Lorem ipsum dolor sit amet consectetur. Et amet nulla in
                  adipiscing. Donec tincidunt dui vel adipiscing sit turpis
                  neque at cursus. Dignissim scelerisque mattis ultricies vitae.
                </p>
              </div>
              <div className="testimonial-footer">
                <div className="logo">
                  <img src={logo} alt="travello" className="changeLogo" />
                </div>
                <p className="date">Jan 20, 2025</p>
              </div>
            </SwiperSlide>
            <SwiperSlide className="testimonial-card">
              <div className="testimonial-header">
                <div className="user-img">
                  <img
                    src={test}
                    alt="travello"
                  />
                </div>
                <div className="user-info">
                  <p className="name">David Malan</p>
                  <p className="designation">Traveler</p>
                </div>
              </div>
              <div className="testimonial-body">
                <p className="pera">
                  Lorem ipsum dolor sit amet consectetur. Et amet nulla in
                  adipiscing. Donec tincidunt dui vel adipiscing sit turpis
                  neque at cursus. Dignissim scelerisque mattis ultricies vitae.
                </p>
              </div>
              <div className="testimonial-footer">
                <div className="logo">
                  <img src={logo} alt="travello" className="changeLogo" />
                </div>
                <p className="date">Jan 20, 2025</p>
              </div>
            </SwiperSlide>
            <SwiperSlide className="testimonial-card">
              <div className="testimonial-header">
                <div className="user-img">
                  <img
                    src={test}
                    alt="travello"
                  />
                </div>
                <div className="user-info">
                  <p className="name">David Malan</p>
                  <p className="designation">Traveler</p>
                </div>
              </div>
              <div className="testimonial-body">
                <p className="pera">
                  Lorem ipsum dolor sit amet consectetur. Et amet nulla in
                  adipiscing. Donec tincidunt dui vel adipiscing sit turpis
                  neque at cursus. Dignissim scelerisque mattis ultricies vitae.
                </p>
              </div>
              <div className="testimonial-footer">
                <div className="logo">
                  <img src={logo} alt="travello" className="changeLogo" />
                </div>
                <p className="date">Jan 20, 2025</p>
              </div>
            </SwiperSlide>
            <SwiperSlide className="testimonial-card">
              <div className="testimonial-header">
                <div className="user-img">
                  <img
                    src={test}
                    alt="travello"
                  />
                </div>
                <div className="user-info">
                  <p className="name">David Malan</p>
                  <p className="designation">Traveler</p>
                </div>
              </div>
              <div className="testimonial-body">
                <p className="pera">
                  Lorem ipsum dolor sit amet consectetur. Et amet nulla in
                  adipiscing. Donec tincidunt dui vel adipiscing sit turpis
                  neque at cursus. Dignissim scelerisque mattis ultricies vitae.
                </p>
              </div>
              <div className="testimonial-footer">
                <div className="logo">
                  <img src={logo} alt="travello" className="changeLogo" />
                </div>
                <p className="date">Jan 20, 2025</p>
              </div>
            </SwiperSlide>
            <SwiperSlide className="testimonial-card">
              <div className="testimonial-header">
                <div className="user-img">
                  <img
                    src={test}
                    alt="travello"
                  />
                </div>
                <div className="user-info">
                  <p className="name">David Malan</p>
                  <p className="designation">Traveler</p>
                </div>
              </div>
              <div className="testimonial-body">
                <p className="pera">
                  Lorem ipsum dolor sit amet consectetur. Et amet nulla in
                  adipiscing. Donec tincidunt dui vel adipiscing sit turpis
                  neque at cursus. Dignissim scelerisque mattis ultricies vitae.
                </p>
              </div>
              <div className="testimonial-footer">
                <div className="logo">
                  <img src={logo} alt="travello" className="changeLogo" />
                </div>
                <p className="date">Jan 20, 2025</p>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="swiper bulletRightSwiper-active">
          <Swiper
            allowTouchMove={true}
            slidesPerView={1}
            speed={6000}
            loop={true}
            autoplay={{
              delay: 0,
              disableOnInteraction: true,
              pauseOnMouseEnter: true,
            }}
            spaceBetween={24}
            breakpoints={{
              1400: {
                slidesPerView: 5,
              },
              992: {
                slidesPerView: 3,
              },
              576: {
                slidesPerView: 2,
              },
            }}
            modules={[Autoplay]}
          >
            <SwiperSlide className="testimonial-card">
              <div className="testimonial-header">
                <div className="user-img">
                  <img
                    src={test}
                    alt="travello"
                  />
                </div>
                <div className="user-info">
                  <p className="name">David Malan</p>
                  <p className="designation">Traveler</p>
                </div>
              </div>
              <div className="testimonial-body">
                <p className="pera">
                  Lorem ipsum dolor sit amet consectetur. Et amet nulla in
                  adipiscing. Donec tincidunt dui vel adipiscing sit turpis
                  neque at cursus. Dignissim scelerisque mattis ultricies vitae.
                </p>
              </div>
              <div className="testimonial-footer">
                <div className="logo">
                  <img src={logo} alt="travello" className="changeLogo" />
                </div>
                <p className="date">Jan 20, 2025</p>
              </div>
            </SwiperSlide>
            <SwiperSlide className="testimonial-card">
              <div className="testimonial-header">
                <div className="user-img">
                  <img
                    src={test}
                    alt="travello"
                  />
                </div>
                <div className="user-info">
                  <p className="name">David Malan</p>
                  <p className="designation">Traveler</p>
                </div>
              </div>
              <div className="testimonial-body">
                <p className="pera">
                  Lorem ipsum dolor sit amet consectetur. Et amet nulla in
                  adipiscing. Donec tincidunt dui vel adipiscing sit turpis
                  neque at cursus. Dignissim scelerisque mattis ultricies vitae.
                </p>
              </div>
              <div className="testimonial-footer">
                <div className="logo">
                  <img src={logo} alt="travello" className="changeLogo" />
                </div>
                <p className="date">Jan 20, 2025</p>
              </div>
            </SwiperSlide>
            <SwiperSlide className="testimonial-card">
              <div className="testimonial-header">
                <div className="user-img">
                  <img
                    src={test}
                    alt="travello"
                  />
                </div>
                <div className="user-info">
                  <p className="name">David Malan</p>
                  <p className="designation">Traveler</p>
                </div>
              </div>
              <div className="testimonial-body">
                <p className="pera">
                  Lorem ipsum dolor sit amet consectetur. Et amet nulla in
                  adipiscing. Donec tincidunt dui vel adipiscing sit turpis
                  neque at cursus. Dignissim scelerisque mattis ultricies vitae.
                </p>
              </div>
              <div className="testimonial-footer">
                <div className="logo">
                  <img src={logo} alt="travello" className="changeLogo" />
                </div>
                <p className="date">Jan 20, 2025</p>
              </div>
            </SwiperSlide>
            <SwiperSlide className="testimonial-card">
              <div className="testimonial-header">
                <div className="user-img">
                  <img
                    src={test}
                    alt="travello"
                  />
                </div>
                <div className="user-info">
                  <p className="name">David Malan</p>
                  <p className="designation">Traveler</p>
                </div>
              </div>
              <div className="testimonial-body">
                <p className="pera">
                  Lorem ipsum dolor sit amet consectetur. Et amet nulla in
                  adipiscing. Donec tincidunt dui vel adipiscing sit turpis
                  neque at cursus. Dignissim scelerisque mattis ultricies vitae.
                </p>
              </div>
              <div className="testimonial-footer">
                <div className="logo">
                  <img src={logo} alt="travello" className="changeLogo" />
                </div>
                <p className="date">Jan 20, 2025</p>
              </div>
            </SwiperSlide>
            <SwiperSlide className="testimonial-card">
              <div className="testimonial-header">
                <div className="user-img">
                  <img
                    src={test}
                    alt="travello"
                  />
                </div>
                <div className="user-info">
                  <p className="name">David Malan</p>
                  <p className="designation">Traveler</p>
                </div>
              </div>
              <div className="testimonial-body">
                <p className="pera">
                  Lorem ipsum dolor sit amet consectetur. Et amet nulla in
                  adipiscing. Donec tincidunt dui vel adipiscing sit turpis
                  neque at cursus. Dignissim scelerisque mattis ultricies vitae.
                </p>
              </div>
              <div className="testimonial-footer">
                <div className="logo">
                  <img src={logo} alt="travello" className="changeLogo" />
                </div>
                <p className="date">Jan 20, 2025</p>
              </div>
            </SwiperSlide>
            <SwiperSlide className="testimonial-card">
              <div className="testimonial-header">
                <div className="user-img">
                  <img
                    src={test}
                    alt="travello"
                  />
                </div>
                <div className="user-info">
                  <p className="name">David Malan</p>
                  <p className="designation">Traveler</p>
                </div>
              </div>
              <div className="testimonial-body">
                <p className="pera">
                  Lorem ipsum dolor sit amet consectetur. Et amet nulla in
                  adipiscing. Donec tincidunt dui vel adipiscing sit turpis
                  neque at cursus. Dignissim scelerisque mattis ultricies vitae.
                </p>
              </div>
              <div className="testimonial-footer">
                <div className="logo">
                  <img src={logo} alt="travello" className="changeLogo" />
                </div>
                <p className="date">Jan 20, 2025</p>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <div className="section-button d-inline-block">
              <Link to="/About">
                <div className="btn-primary-icon-sm">
                  <p className="pera">All Customers Say</p>
                  <i className="ri-arrow-right-up-line"></i>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonial;
