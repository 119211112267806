import React from "react";

const DestinationContent = () => {
  return (
    <div>
      <div className="destination-details-content">
        <h4 className="title">Trending Destinations - World</h4>
        <p className="pera">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum."
        </p>
        <p className="pera">
          Sed ut perspiciatis unde omnis iste natus error sit voluptatem
          accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae
          ab illo inventore veritatis et quasi architecto beatae vitae dicta
          sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit
          aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos
          qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui
          dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed
          quia non numquam eius modi tempora incidunt ut labore et dolore magnam
          aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum
          exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex
          ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in
          ea voluptate velit esse quam nihil molestiae consequatur, vel illum
          qui dolorem eum fugiat quo voluptas nulla pariatur?"
        </p>
      </div>
      <div className="destination-details-info">
        <h4 className="title">Basic Information</h4>
        <div className="info-table">
          <table className="table">
            <tbody>
              <tr>
                <th>Country</th>
                <td>Australia</td>
              </tr>
              <tr>
                <th>Language</th>
                <td>English. Bangla. Spanish</td>
              </tr>
              <tr>
                <th>Currency</th>
                <td>USD Dollar</td>
              </tr>
              <tr>
                <th>Population</th>
                <td>7M</td>
              </tr>
              <tr>
                <th>Area</th>
                <td>1500 Square Miles</td>
              </tr>
              <tr>
                <th>Time to Travel</th>
                <td>January, 2025</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="destination-details-content">
        <h4 className="title">What to Expect</h4>
        <p className="pera">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur.
        </p>
        <ul className="expect-list">
          <li className="list">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore.
          </li>
          <li className="list">
            Sed ut perspiciatis unde omnis iste natus error sit voluptatem
            accusantium doloremque laudantium, totam rem
          </li>
          <li className="list">
            Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
            officia deserunt mollit anim id est laborum.
          </li>
          <li className="list">
            At vero eos et accusamus et iusto odio dignissimos ducimus qui
            blanditiis praesentium voluptatum deleniti atque corrupti quos
            dolores
          </li>
        </ul>
      </div>
      <div className="destination-accordion">
        <div className="accordion" id="accordionPanelsStayOpenExample">
          <div className="accordion-item">
            <h2 className="accordion-header" id="panelsStayOpen-headingOne">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseOne"
                aria-expanded="true"
                aria-controls="panelsStayOpen-collapseOne"
              >
                What are best quotes in travel?
              </button>
            </h2>
            <div
              id="panelsStayOpen-collapseOne"
              className="accordion-collapse collapse show"
              aria-labelledby="panelsStayOpen-headingOne"
            >
              <div className="accordion-body">
                <ul className="listing">
                  <li className="list">
                    “Life is either a daring adventure or nothing at all.” ...
                  </li>
                  <li className="list">
                    “Travel far enough, you meet yourself.” ...
                  </li>
                  <li className="list">
                    “Wherever you go becomes a part of you somehow.” ...
                  </li>
                  <li className="list">
                    “Once a year, go someplace you've never been before.”
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseTwo"
                aria-expanded="false"
                aria-controls="panelsStayOpen-collapseTwo"
              >
                What is a good quote about tourism?
              </button>
            </h2>
            <div
              id="panelsStayOpen-collapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="panelsStayOpen-headingTwo"
            >
              <div className="accordion-body">
                <ul className="listing">
                  <li className="list">
                    “Life is either a daring adventure or nothing at all.” ...
                  </li>
                  <li className="list">
                    “Travel far enough, you meet yourself.” ...
                  </li>
                  <li className="list">
                    “Wherever you go becomes a part of you somehow.” ...
                  </li>
                  <li className="list">
                    “Once a year, go someplace you've never been before.”
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="panelsStayOpen-headingThree">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseThree"
                aria-expanded="false"
                aria-controls="panelsStayOpen-collapseThree"
              >
                How do you write a quote for a trip?
              </button>
            </h2>
            <div
              id="panelsStayOpen-collapseThree"
              className="accordion-collapse collapse"
              aria-labelledby="panelsStayOpen-headingThree"
            >
              <div className="accordion-body">
                <ul className="listing">
                  <li className="list">
                    “Life is either a daring adventure or nothing at all.” ...
                  </li>
                  <li className="list">
                    “Travel far enough, you meet yourself.” ...
                  </li>
                  <li className="list">
                    “Wherever you go becomes a part of you somehow.” ...
                  </li>
                  <li className="list">
                    “Once a year, go someplace you've never been before.”
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DestinationContent;
