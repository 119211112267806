import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
// import required modules
import { Autoplay } from "swiper/modules";
import DestinationContent from "./DestinationContent";
import DestinationOffer from "./DestinationOffer";
import DestinationTourList from "./DestinationTourList";
import Header from "../../../Components/Header/Header";
import Breadcrumbs from "../../../Common/Breadcrumbs";
import Footer from "../../../Components/Footer/Footer";
import dest from "../../../Assets/images/destination/details.png"

const DestinationDetail = ({ toggleTheme }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header toggleTheme={toggleTheme} />
      <Breadcrumbs heading="Destination Detail" link="/DestinationDetail" />

      <section className="destination-details-section section-padding2">
        <div className="container">
          <div className="row g-4">
            <div className="col-xl-8 col-lg-7">
              <div className="destination-details-banner o-hidden radius-12">
                <div className="swiper destinationSwiper-active">
                  <Swiper
                    slidesPerView={1}
                    speed={1000}
                    loop={true}
                    autoplay={{
                      delay: 2000,
                      disableOnInteraction: false,
                      pauseOnMouseEnter: true,
                    }}
                    modules={[Autoplay]}
                  >
                    <SwiperSlide>
                      <img
                        src={dest}
                        alt="travello"
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img
                        src={dest}
                        alt="travello"
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img
                        src={dest}
                        alt="travello"
                      />
                    </SwiperSlide>
                  </Swiper>
                </div>
              </div>
              <DestinationContent />
            </div>
            <div className="col-xl-4 col-lg-5">
              <div className="row g-4 position-static top-0">
                <div className="col-lg-12">
                  <DestinationOffer />
                </div>
                <div className="col-lg-12">
                  <DestinationTourList />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default DestinationDetail;
