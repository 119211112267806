import React, { useEffect } from "react";
import Header from "../../../Components/Header/Header";
import Breadcrumbs from "../../../Common/Breadcrumbs";
import Footer from "../../../Components/Footer/Footer";
import { Link } from "react-router-dom";
import DestinationOffer from "../DestinationDetail/DestinationOffer";
import dest from "../../../Assets/images/destination/destination-7.png"
import dest1 from "../../../Assets/images/destination/destination-9.png"
import dest2 from "../../../Assets/images/destination/destination-1.png"
import dest3 from "../../../Assets/images/destination/destination-2.png"

const DestinationList = ({ toggleTheme }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header toggleTheme={toggleTheme} />
      <Breadcrumbs heading="Destination" link="/DestinationList" />

      <section className="destination-section bottom-padding1">
        <div className="destination-area">
          <div className="container">
            <div className="row g-4">
              <div className="col-lg-4 col-sm-6">
                <Link
                  to="/DestinationDetail"
                  className="destination-banner h-calc"
                >
                  <img
                    src={dest}
                    alt="travello"
                  />
                  <div className="destination-content">
                    <div className="ratting-badge">
                      <i className="ri-star-s-fill"></i>
                      <span>4.5</span>
                    </div>
                    <div className="destination-info">
                      <div className="destination-name">
                        <p className="pera">Spain</p>
                        <div className="location">
                          <i className="ri-map-pin-line"></i>
                          <p className="name">Malaga View</p>
                        </div>
                      </div>
                      <div className="button-section">
                        <div className="arrow-btn">
                          <i className="ri-arrow-right-line"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-sm-6">
                <Link
                  to="/DestinationDetail"
                  className="destination-banner h-calc"
                >
                  <img
                    src={dest}
                    alt="travello"
                  />
                  <div className="destination-content">
                    <div className="ratting-badge">
                      <i className="ri-star-s-fill"></i>
                      <span>4.5</span>
                    </div>
                    <div className="destination-info">
                      <div className="destination-name">
                        <p className="pera">Spain</p>
                        <div className="location">
                          <i className="ri-map-pin-line"></i>
                          <p className="name">Malaga View</p>
                        </div>
                      </div>
                      <div className="button-section">
                        <div className="arrow-btn">
                          <i className="ri-arrow-right-line"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-sm-6">
                <Link
                  to="/DestinationDetail"
                  className="destination-banner h-calc"
                >
                  <img
                    src={dest}
                    alt="travello"
                  />
                  <div className="destination-content">
                    <div className="ratting-badge">
                      <i className="ri-star-s-fill"></i>
                      <span>4.5</span>
                    </div>
                    <div className="destination-info">
                      <div className="destination-name">
                        <p className="pera">Spain</p>
                        <div className="location">
                          <i className="ri-map-pin-line"></i>
                          <p className="name">Malaga View</p>
                        </div>
                      </div>
                      <div className="button-section">
                        <div className="arrow-btn">
                          <i className="ri-arrow-right-line"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-8 col-sm-6">
                <Link
                  to="/DestinationDetail"
                  className="destination-banner h-calc"
                >
                  <img
                    src={dest1}
                    alt="travello"
                  />
                  <div className="destination-content">
                    <div className="ratting-badge">
                      <i className="ri-star-s-fill"></i>
                      <span>4.5</span>
                    </div>
                    <div className="destination-info">
                      <div className="destination-name">
                        <p className="pera">New Zealand</p>
                        <div className="location">
                          <i className="ri-map-pin-line"></i>
                          <p className="name">Auckland View</p>
                        </div>
                      </div>
                      <div className="button-section">
                        <div className="arrow-btn">
                          <i className="ri-arrow-right-line"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-sm-6">
                <DestinationOffer />
              </div>
              <div className="col-lg-5 col-sm-6">
                <Link
                  to="/DestinationDetail"
                  className="destination-banner h-calc"
                >
                  <img
                    src={dest3}
                    alt="travello"
                  />
                  <div className="destination-content">
                    <div className="ratting-badge">
                      <i className="ri-star-s-fill"></i>
                      <span>4.5</span>
                    </div>
                    <div className="destination-info">
                      <div className="destination-name">
                        <p className="pera">New Zealand</p>
                        <div className="location">
                          <i className="ri-map-pin-line"></i>
                          <p className="name">Auckland View</p>
                        </div>
                      </div>
                      <div className="button-section">
                        <div className="arrow-btn">
                          <i className="ri-arrow-right-line"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-7 col-sm-6">
                <Link
                  to="/DestinationDetail"
                  className="destination-banner h-calc"
                >
                  <img
                    src={dest2}
                    alt="travello"
                  />
                  <div className="destination-content">
                    <div className="ratting-badge">
                      <i className="ri-star-s-fill"></i>
                      <span>4.5</span>
                    </div>
                    <div className="destination-info">
                      <div className="destination-name">
                        <p className="pera">Spain</p>
                        <div className="location">
                          <i className="ri-map-pin-line"></i>
                          <p className="name">Malaga View</p>
                        </div>
                      </div>
                      <div className="button-section">
                        <div className="arrow-btn">
                          <i className="ri-arrow-right-line"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-sm-6">
                <Link
                  to="/DestinationDetail"
                  className="destination-banner h-calc"
                >
                  <img
                    src={dest}
                    alt="travello"
                  />
                  <div className="destination-content">
                    <div className="ratting-badge">
                      <i className="ri-star-s-fill"></i>
                      <span>4.5</span>
                    </div>
                    <div className="destination-info">
                      <div className="destination-name">
                        <p className="pera">Switzerland</p>
                        <div className="location">
                          <i className="ri-map-pin-line"></i>
                          <p className="name">Zürich View</p>
                        </div>
                      </div>
                      <div className="button-section">
                        <div className="arrow-btn">
                          <i className="ri-arrow-right-line"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-sm-6">
                <Link
                  to="/DestinationDetail"
                  className="destination-banner h-calc"
                >
                  <img
                    src={dest}
                    alt="travello"
                  />
                  <div className="destination-content">
                    <div className="ratting-badge">
                      <i className="ri-star-s-fill"></i>
                      <span>4.5</span>
                    </div>
                    <div className="destination-info">
                      <div className="destination-name">
                        <p className="pera">Australia</p>
                        <div className="location">
                          <i className="ri-map-pin-line"></i>
                          <p className="name">Melbourne View</p>
                        </div>
                      </div>
                      <div className="button-section">
                        <div className="arrow-btn">
                          <i className="ri-arrow-right-line"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-sm-6">
                <Link
                  to="/DestinationDetail"
                  className="destination-banner h-calc"
                >
                  <img
                    src={dest}
                    alt="travello"
                  />
                  <div className="destination-content">
                    <div className="ratting-badge">
                      <i className="ri-star-s-fill"></i>
                      <span>4.5</span>
                    </div>
                    <div className="destination-info">
                      <div className="destination-name">
                        <p className="pera">Canada</p>
                        <div className="location">
                          <i className="ri-map-pin-line"></i>
                          <p className="name">Toronto View</p>
                        </div>
                      </div>
                      <div className="button-section">
                        <div className="arrow-btn">
                          <i className="ri-arrow-right-line"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default DestinationList;
