import React from "react";
import { Link } from "react-router-dom";

const DestinationOffer = () => {
  return (
    <div className="destination-offer-three">
      <div className="destination-content-offer">
        <span className="highlights">20% off</span>
        <h4 className="title">The Best Travel Adventure</h4>
        <Link to="/" className="btn-secondary-sm radius-30">
          Booking Now
        </Link>
      </div>
    </div>
  );
};

export default DestinationOffer;
