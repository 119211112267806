import { useEffect, useRef, useState } from "react";
import $ from "jquery";
import "select2/dist/js/select2.full.min.js";
import "select2/dist/css/select2.min.css";
import "daterangepicker/daterangepicker.css";
import "daterangepicker";

const useFilterCommon = () => {
  const [adults, setAdults] = useState(0);
  const [children, setChildren] = useState(0);
  const [infants, setInfants] = useState(0);
  const [totalGuests, setTotalGuests] = useState(0);
  const [isGuestDropdownOpen, setIsGuestDropdownOpen] = useState(false);
  const [showTotalGuests, setShowTotalGuests] = useState(false);
  const [selectedTourType, setSelectedTourType] = useState(null); // For storing selected tour type

  useEffect(() => {
    setTotalGuests(adults + children + infants);
  }, [adults, children, infants]);

  const handleAdultsChange = (value) => {
    if (value >= 0) {
      setAdults(value);
    }
  };

  const handleChildrenChange = (value) => {
    if (value >= 0) {
      setChildren(value);
    }
  };

  const handleInfantsChange = (value) => {
    if (value >= 0) {
      setInfants(value);
    }
  };

  const handleGuestClick = () => {
    setIsGuestDropdownOpen(!isGuestDropdownOpen); // Toggle the dropdown state
  };

  const destinationRef = useRef(null);
  const tourTypeRef = useRef(null);

  useEffect(() => {
    // Store references in local variables
    const destinationSelect = $(destinationRef.current);
    const tourTypeSelect = $(tourTypeRef.current);

    // Initialize Select2 for destination and tour type dropdowns
    destinationSelect.select2({
      containerCssClass: "custom-select2-dropdown",
      dropdownCssClass: "custom-select2-dropdown-container",
    });

    tourTypeSelect.select2({
      containerCssClass: "custom-select2-dropdown",
      dropdownCssClass: "custom-select2-dropdown-container",
    });

    // Attach change event listener to select2 for tour type
    tourTypeSelect.on("change", function () {
      const selectedTourTypeValue = $(this).val(); // Get the selected value
      handleTourTypeChange(selectedTourTypeValue); // Call the change handler
    });

    // Clean up function
    return () => {
      // Destroy Select2 instances using local variables
      if (destinationSelect && destinationSelect.length) {
        destinationSelect.select2("destroy");
      }
      if (tourTypeSelect && tourTypeSelect.length) {
        tourTypeSelect.select2("destroy");
      }
    };
  }, []);

  const handleTourTypeChange = (tourType) => {
    setSelectedTourType(tourType);

    // Now push this value to the URL without navigation
    const url = new URL(window.location.href);
    url.searchParams.set("tourtype", tourType); // Set the tourType in URL params
    window.history.pushState({}, "", url); // Update the URL without navigation
  };

  const handleDateDropdownClick = () => {
    $('input[name="daterange"]').data("daterangepicker").show();
  };

  useEffect(() => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate()); // Tomorrow's date

    // Initialize DateRangePicker
    const datepicker = $('input[name="daterange"]')
      .daterangepicker(
        {
          opens: "left",
          startDate: today,
          minDate: tomorrow,
        },
        function (start, end, label) {
          console.log(
            "A new date selection was made: " +
              start.format("YYYY-MM-DD") +
              " to " +
              end.format("YYYY-MM-DD")
          );
        }
      )
      .data("daterangepicker");

    // Clean up function
    return () => {
      if (datepicker) {
        datepicker.remove();
      }
    };
  }, []);

  return {
    totalGuests,
    handleAdultsChange,
    handleChildrenChange,
    handleInfantsChange,
    handleGuestClick,
    handleDateDropdownClick,
    destinationRef,
    tourTypeRef,
    adults,
    children,
    isGuestDropdownOpen,
    infants,
    setIsGuestDropdownOpen,
    showTotalGuests,
    setShowTotalGuests,
    selectedTourType,
    handleTourTypeChange,
  };
};

export default useFilterCommon;
