import React from "react";

const Package = ({ tours, handleTabClick, activeTab }) => {
  return (
    <section className="explore-area section-padding2">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-7">
            <div className="section-title text-center mx-430 mx-auto position-relative mb-60">
              <span className="highlights">Explore The Word</span>
              <h4 className="title">Our Best Offer Package For You</h4>
            </div>
          </div>
        </div>
        <div className="row g-4">
          <div className="col-xl-5 col-lg-5 col-md-5">
            <div
              className="all-explore"
              id="v-pills-tab-three"
              role="tablist"
              aria-orientation="vertical"
            >
              {tours &&
                tours.map((tour) => (
                  <div
                    key={tour.tourTypeId}
                    className={`explore-btn ${
                      activeTab === tour.tourTypeId ? "active" : ""
                    }`}
                    id={`pills-explore-${tour.tourTypeId}-tab`}
                    data-bs-toggle="pill"
                    data-bs-target={`#pills-explore-${tour.tourTypeId}`}
                    role="tab"
                    aria-controls={`pills-explore-${tour.tourTypeId}`}
                    aria-selected={activeTab === tour.tourTypeId}
                    onClick={() => handleTabClick(tour.tourTypeId)}
                  >
                    <div className="d-flex gap-16 align-items-center">
                      {/* <div className="explore-icon">
                        <img
                          src={`assets/images/icon/explore-${tour.tourTypeId}.svg`} // Update icon src dynamically
                          alt={tour.tourTypeName}
                        />
                      </div> */}
                      <h4 className="name">{tour.tourTypeName}</h4>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className="col-xl-7 col-lg-7 col-md-7">
            <div className="tab-content" id="v-pills-tabContent-three">
              {tours &&
                tours.map((tour) => (
                  <div
                    key={tour.tourTypeId}
                    className={`tab-pane fade ${
                      activeTab === tour.tourTypeId ? "show active" : ""
                    }`}
                    id={`pills-explore-${tour.tourTypeId}`}
                    role="tabpanel"
                    aria-labelledby={`pills-explore-${tour.tourTypeId}-tab`}
                  >
                    <div className="explore-content">
                      <h4 className="title">{tour.tourTypeName}</h4>
                      <div
                        className="pera"
                        dangerouslySetInnerHTML={{
                          __html: tour.tourTypeDetail,
                        }}
                      />
                    </div>
                    {/* <div className="explore-banner">
                      <img
                        src={`assets/images/gallery/${tour.tourTypeId}.png`}
                        alt={tour.tourTypeName}
                      />
                    </div> */}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Package;
