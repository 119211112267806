import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
// Import required modules
import { Autoplay, Navigation } from "swiper/modules";
import { useApi } from "../../Context/ApiProvider";
import { Image_URL } from "../../Common/BaseURL";

const Hero = () => {
  const [banners, setBanners] = useState([]);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const api = useApi();
  const swiperRef = useRef(null);

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const res = await api.get("Banner/BannerSlider?bannerType=Home");
        if (res.body.data) {
          setBanners(res.body.data);
        }
      } catch (error) {
        console.error("Error fetching banners:", error);
      }
    };

    const handleFetchData = () => {
      fetchBanners();
    };

    const timeoutId = setTimeout(handleFetchData, 100);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [api]); // include api if it is subject to change

  const handleSlideChange = (swiper) => {
    // Set index within bounds
    const index = swiper.realIndex;
    setCurrentSlideIndex(index);
  };

  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.update(); // Update Swiper when new slides are added
    }
  }, []);

  return (
    <section className="hero-padding-for-three video-overlay position-relative">
      <div className="hero-bg-video favSwiper-active">
        <Swiper
          loop={true}
          spaceBetween={0}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          modules={[Navigation, Autoplay]}
          className="hero-slider-image"
          onSlideChange={handleSlideChange}
          onInit={(swiper) => {
            swiperRef.current = swiper;
          }}
        >
          {banners.map((banner, index) => (
            <SwiperSlide key={index}>
              <img
                src={`${Image_URL}/banner/${banner.imagePath}`}
                alt={`Banner ${index + 1}`}
                className="hero-image"
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="swiper-button-next">
          <i className="ri-arrow-right-s-line"></i>
        </div>
        <div className="swiper-button-prev">
          <i className="ri-arrow-left-s-line"></i>
        </div>
      </div>
      <div className="container">
        <div className="row align-items-center justify-content-between g-4">
          <div className="col-xl-12">
            {banners.length > 0 && (
              <div className="hero-caption-three position-relative z-3">
                <h4 className="title wow fadeInUp" data-wow-delay="0.0s">
                  {banners[currentSlideIndex]?.bannerName || "Loading..."}
                </h4>
                <p className="pera wow fadeInUp" data-wow-delay="0.1s">
                  {banners[currentSlideIndex]?.bannerDescription ||
                    "Loading..."}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
