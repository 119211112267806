import React from "react";
import { Link } from "react-router-dom";
import dest from "../../../Assets/images/destination/destination-4.png";

const DestinationTourList = () => {
  return (
    <div className="tour-list-card">
      <h4 className="title">Tour List</h4>
      <ul className="tour-listing">
        <li className="list">
          <div className="package-img imgEffect4">
            <Link to="/TourDetail">
              <img src={dest} alt="travello" />
            </Link>
          </div>
          <div className="package-content">
            <h4 className="area-name">
              <Link to="/TourDetail">Dusitd2 Samyan Bangkok</Link>
            </h4>
            <div className="location">
              <i className="ri-map-pin-line"></i>
              <div className="name">Bangkok, Thailand</div>
            </div>
            <div className="price-review">
              <div className="d-flex gap-10">
                <p className="light-pera">From</p>
                <p className="pera">₹95</p>
              </div>
              <div className="rating">
                <i className="ri-star-s-fill"></i>
                <p className="pera">4.7 (20 Reviews)</p>
              </div>
            </div>
          </div>
        </li>
        <li className="list">
          <div className="package-img imgEffect4">
            <Link to="/TourDetail">
              <img src={dest} alt="travello" />
            </Link>
          </div>
          <div className="package-content">
            <h4 className="area-name">
              <Link to="/TourDetail">Dusitd2 Samyan Bangkok</Link>
            </h4>
            <div className="location">
              <i className="ri-map-pin-line"></i>
              <div className="name">Bangkok, Thailand</div>
            </div>
            <div className="price-review">
              <div className="d-flex gap-10">
                <p className="light-pera">From</p>
                <p className="pera">₹95</p>
              </div>
              <div className="rating">
                <i className="ri-star-s-fill"></i>
                <p className="pera">4.7 (20 Reviews)</p>
              </div>
            </div>
          </div>
        </li>
        <li className="list">
          <div className="package-img imgEffect4">
            <Link to="/TourDetail">
              <img src={dest} alt="travello" />
            </Link>
          </div>
          <div className="package-content">
            <h4 className="area-name">
              <Link to="/TourDetail">Dusitd2 Samyan Bangkok</Link>
            </h4>
            <div className="location">
              <i className="ri-map-pin-line"></i>
              <div className="name">Bangkok, Thailand</div>
            </div>
            <div className="price-review">
              <div className="d-flex gap-10">
                <p className="light-pera">From</p>
                <p className="pera">₹95</p>
              </div>
              <div className="rating">
                <i className="ri-star-s-fill"></i>
                <p className="pera">4.7 (20 Reviews)</p>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div className="text-center mt-10">
        <Link to="/TourList" className="see-more-text" aria-label="see">
          See More
        </Link>
      </div>
    </div>
  );
};

export default DestinationTourList;
