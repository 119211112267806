import React from "react";
import { Link } from "react-router-dom";

const FooterBottomArea = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="d-flex justify-content-between gap-14 flex-wrap">
            <p className="pera">
              © <span className="current-year">2023</span> initTheme. All rights
              reserved
            </p>
            <p className="pera">
              Developed by <Link to="https://www.devkido.com"> @Devkido</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterBottomArea;
