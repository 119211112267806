import React from "react";
import include from "../../../Assets/images/icon/checkmark.png";
import exclude from "../../../Assets/images/icon/closemark.png";

const TourContent = ({ content }) => {
  // Helper function to get city names by days
  const getCityByDays = (days, destinations) => {
    const cityMap = destinations.reduce((acc, dest) => {
      if (!acc[dest.days]) {
        acc[dest.days] = [];
      }
      acc[dest.days].push(dest.city);
      return acc;
    }, {});
    return cityMap[days] || [];
  };

  return (
    <div>
      <div className="tour-details-content">
        <h4 className="title">About</h4>
        <div
          className="pera"
          dangerouslySetInnerHTML={{ __html: content?.packageDetail }}
        />
      </div>

      <div className="tour-details-content">
        <h4 className="title">Highlight</h4>
        <div
          className="pera"
          dangerouslySetInnerHTML={{ __html: content?.packageHighLight }}
        />
      </div>

      {content?.includeExclude.length > 0 && (
        <div className="tour-include-exclude radius-6">
          <div className="includ-exclude-point">
            <h4 className="title">Included</h4>
            <ul className="expect-list">
              {content?.includeExclude
                .filter((item) => item.include)
                .map((item, index) => (
                  <li key={index} className="list">
                    <img
                      src={include}
                      alt="included"
                      width={15}
                      height={15}
                      style={{ marginRight: "8px" }}
                    />{" "}
                    {item.facility}
                  </li>
                ))}
            </ul>
          </div>
          <div className="divider"></div>
          <div className="includ-exclude-point">
            <h4 className="title">Exclude</h4>
            <ul className="expect-list">
              {content?.includeExclude
                .filter((item) => !item.include)
                .map((item, index) => (
                  <li key={index} className="list">
                    <img
                      src={exclude}
                      alt="excluded"
                      width={15}
                      height={15}
                      style={{ marginRight: "8px" }}
                    />{" "}
                    {item.facility}
                  </li>
                ))}
            </ul>
          </div>
        </div>
      )}

      {content?.planDetail.length > 0 && (
        <div className="tour-details-content mb-30">
          <h4 className="title">Tour Plan</h4>
          <div className="destination-accordion">
            <div className="accordion" id="accordionPanelsStayOpenExample">
              {content?.planDetail.map((plan, index) => {
                const headingId = `panelsStayOpen-heading${index}`;
                const collapseId = `panelsStayOpen-collapse${index}`;
                const cityNames = getCityByDays(
                  plan?.days,
                  content?.destinations
                );

                return (
                  <div className="accordion-item" key={index}>
                    <h2 className="accordion-header" id={headingId}>
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#${collapseId}`}
                        aria-expanded={index === 0 ? "true" : "false"}
                        aria-controls={collapseId}
                      >
                        Day {plan?.days} - {plan?.planTitle}
                      </button>
                    </h2>
                    <div
                      id={collapseId}
                      className={`accordion-collapse collapse ${
                        index === 0 ? "show" : ""
                      }`}
                      aria-labelledby={headingId}
                    >
                      <div className="accordion-body">
                        {cityNames.length > 0 && (
                          <div className="city-list">
                            <h6>Cities:</h6>
                            {cityNames.map((city, i) => (
                              <div className="city-item" key={i}>
                                <span className="city-icon"></span>
                                <span className="city-name">{city}</span>
                              </div>
                            ))}
                          </div>
                        )}

                        <div
                          dangerouslySetInnerHTML={{
                            __html: plan?.dayPlanDetail,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}

      <div className="tour-details-content">
        <h4 className="title">Policy</h4>
        <div
          className="pera"
          dangerouslySetInnerHTML={{ __html: content?.packagePolicy }}
        />
      </div>
    </div>
  );
};

export default TourContent;
